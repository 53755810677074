import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02905c5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row content flex-column-reverse flex-lg-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_application_info = _resolveComponent("application-info")!
  const _component_application_file_upload = _resolveComponent("application-file-upload")!
  const _component_application_status = _resolveComponent("application-status")!
  const _component_application_checklist = _resolveComponent("application-checklist")!
  const _component_page = _resolveComponent("page")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
    : (_openBlock(), _createBlock(_component_page, { key: 1 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(["col-12 col-lg-3 col-xl-3 sidenav cuscd-item", [_ctx.showTile === true ? '' : 'hide']])
              }, [
                _createVNode(_component_application_info, {
                  id: "applicationInformation",
                  application: _ctx.appData.application,
                  applicant: _ctx.appData.currentApplicant,
                  onComplete: _ctx.stepComplete,
                  onHydrateStore: _ctx.hydrateStore
                }, null, 8, ["application", "applicant", "onComplete", "onHydrateStore"]),
                (!_ctx.isLoanBoarded)
                  ? (_openBlock(), _createBlock(_component_application_file_upload, {
                      key: 0,
                      id: "applicationFileUpload",
                      application: _ctx.appData.application,
                      applicant: _ctx.appData.currentApplicant
                    }, null, 8, ["application", "applicant"]))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["col-12 col-xl-5 text-left cuscd-item", [_ctx.showTile === true ? 'col-lg-4' : 'col-lg-6']]),
                id: "private-line"
              }, [
                _createVNode(_component_application_status, {
                  application: _ctx.appData.application,
                  applicant: _ctx.appData.currentApplicant
                }, null, 8, ["application", "applicant"])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["col-12 col-lg-5 col-xl-4 sidenav cuscd-item cuscd-app-side", [_ctx.showTile === true ? '' : 'hide']]),
                id: "app-prog"
              }, [
                _createVNode(_component_application_checklist, {
                  application: _ctx.appData.application,
                  applicant: _ctx.appData.currentApplicant,
                  onHydrateStore: _ctx.hydrateStore
                }, null, 8, ["application", "applicant", "onHydrateStore"])
              ], 2)
            ])
          ])
        ]),
        _: 1
      }))
}