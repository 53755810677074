<template >
  <div class="status-bar">
    <div class="step complete">
      <div>
        <div class="circle">✓</div>
      </div>
      <div>
        <div class="title">Credit Approved --> Completed!</div>
      </div>
    </div>
    <div class="step complete">
      <div>
        <div class="circle">✓</div>
      </div>
      <div>
        <div class="title">Documents & Verification --> Completed!</div>
      </div>
    </div>
    <div class="step complete">
      <div>
        <div class="circle">✓</div>
      </div>
      <div>
        <div class="title">Sign Final Documents --> Completed!</div>
        <div class="caption"></div>
      </div>
    </div>
    <div class="step active">
      <div>
        <div class="circle">4</div>
      </div>
      <div>
        <div class="title">School Certification</div>
        <div class="caption">Usually 2-13 days</div>
      </div>
    </div>
  </div>
</template>