
import { PropType, defineComponent, ref, computed, reactive } from "vue";
import { useStore, mapState, mapGetters } from "vuex";
import { $api } from "@/services/api";
import { Applicant } from "@/models/application";
import { LOSType } from "@/models/opal";
import { InviteRequest } from "@/models/api";
import Tile from "@/components/shared/Tile.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
import CancellationModal from "@/components/modals/CancellationModal.vue";
import useFormatter from "@/use/formatter";
import useFileUtilities from "@/use/fileUtilities";
import Complete from "@/components/application-statuses/Complete.vue";
import Cancelled from "@/components/application-statuses/Cancelled.vue";
import Declined from "@/components/application-statuses/Declined.vue";
import EvaluationQueue from "@/components/application-statuses/EvaluationQueue.vue";
import NotSubmitted from "@/components/application-statuses/NotSubmitted.vue";
import SchoolCertification from "@/components/application-statuses/SchoolCertification.vue";
import SubmittedAndProcessing from "@/components/application-statuses/SubmittedAndProcessing.vue";


export default defineComponent({
  components: {
    Tile, AlertModal, CancellationModal,
    Complete,
    Cancelled,
    Declined,
    EvaluationQueue,
    SchoolCertification,
    SubmittedAndProcessing,

  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    applicant: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { formatDateMMDDYYY } = useFormatter();
    const { base64ToBlob } = useFileUtilities();
    const applicationStatus = computed<string>(()=> props?.application?.applicationStatusName?.trim().toLowerCase());

    const showCancellationLink = computed(() => {
      return (applicationStatus.value === 'not submitted' || applicationStatus.value === 'evaluation' || applicationStatus.value === 'submitted & processing');
    });
    // template refs
    const fileAccess = ref() as any;

    // Data
    const cancelApplication = computed(() => store.getters['modal/cancellationModalActive']);

    const docAvailable = ref<boolean | undefined>(undefined);
    const showRestartLink = ref<boolean | undefined>(undefined);
    const showResendInviteLink = ref<boolean | undefined>(undefined);
    const showReturnLink = ref<boolean | undefined>(undefined);
    const gettingData = ref<boolean>(true);
    const inviteResent = ref<boolean | undefined>(undefined);
    const isInvitedApplicant = ref<boolean | undefined>(undefined);
    const isSecondaryApplicant = ref<boolean | undefined>(undefined);
    const inviteAccepted = ref<boolean | undefined>(undefined);
    const resendInviteError = ref<boolean>(false);
    const secondaryApplicantIsRegistered = ref<boolean | undefined>(undefined);
    const restartLink = computed(() =>`${store?.state?.configuration?.borrowerApplicationUrl}?lid=${store?.state?.lenderData?.lid}&productTypeId=${store?.state?.appData?.application?.productTypeId}&leadSourceId=1&programTypeId=${store?.state?.appData?.application?.programTypeId}`);
    let secondaryApplicantInfo = reactive({} as Applicant);
    let invite = reactive({} as any);


    const applicationId = computed(() => props.application.id);
    const soloApp = computed(() => props.application.metadata.withCoApplicants === false);
    const tollFreeNumber = computed<string>(() => {
      return store.state.lenderData && store.state.lenderData.tollFreeNumber
        ? store.state.lenderData.tollFreeNumber
        : "N/A";
    });

    const emailAddress = computed<string>(() => {
      return store.state.lenderData && store.state.lenderData.supportEmailAddress
        ? store.state.lenderData && store.state.lenderData.supportEmailAddress
        : "member_support@studentchoice.org";
    });

    async function resendInvite() {
      const appId = applicationId.value;
      if (inviteResent.value === undefined || inviteResent.value === false) {
        console.log('invites', store.state.invites);
        console.log('invite', store.state.invites[0]);

          let inviteRequest = {
            applicationId: appId,
            invitedByApplicantId: props.applicant.id,
            invitedApplicantTypeId: store.state.invites[0]?.applicantTypeId,
            firstName: store.state.invites[0]?.firstName,
            middleName: store.state.invites[0]?.middleName,
            lastName: store.state.invites[0]?.lastName,
            emailAddress: store.state.invites[0]?.emailAddress
          } as InviteRequest;
          // Get current invite and use the response to build the request body
          let response = await $api.applications.resendInvite(appId, inviteRequest);
          if (response?.err || response?.error || response?.invites[0].resendAtUtc === null) {
            resendInviteError.value = true;
          } else {
            inviteResent.value = true;
          }
      }
    }

    async function redirectToBorrowerApp() {
      let link = await $api.return.getReturnLink(
        store.state.configuration.borrowerApplicationUrl,
        store.state.appData.application.id
      );

      console.log("applicant portal link", link?.data?.token);
      location.href = `${store.state.configuration.borrowerApplicationUrl}/return?applicationId=${store.state.appData.application.id}&token=${link?.data?.token}`;
    }

    function addDays(date: Date, days: number) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    function openCancellationModal() {
      store.dispatch('modal/openCancellationModal');
    }

    async function checkForLetter() {
      // get docTypeId for AA
      const docType = store.state.lookupsModule.lookups.documentTypes.find((t: LOSType) => t.name === "Adverse Action");
      if (docType) {
        const notice = await $api.documents.getApplicationDocumentByType(props.application.id, docType.id);

        let b64 = await $api.documents.getDocument(notice.pop().documentId);
        if (b64) {
          docAvailable.value = true;
        } else {
          docAvailable.value = false;
        }
      }
    }
    async function downloadAdverseActionNotice() {
      console.log("downloadAdverseActionNotice()");

      let notice = await $api.documents.getApplicationDocumentByType(
        props.application.id,
        25
      );
      console.log("notice", notice);

      let b64 = await $api.documents.getDocument(notice.pop().documentId);

      console.log("b64", b64);
      const blob = base64ToBlob(b64);
      console.log(`blob ${new Date()}`, blob);

      if (blob.size > 0) {
        const blobURL = URL.createObjectURL(blob);
        let a = fileAccess as HTMLAnchorElement;
        // console.log("this.$refs.link", this.$refs.link);
        a.setAttribute("download", "Application.pdf");
        a.setAttribute("href", `${blobURL}`);
        a.click();
        URL.revokeObjectURL(blobURL);
      } else {
        // #TODO do they want messaging if download fails?
        console.log("FAILED TO DOWNLOAD");
      }
    }
    return {
      checkForLetter,
      resendInvite,
      addDays,
      redirectToBorrowerApp,
      downloadAdverseActionNotice,
      showCancellationLink,
      formatDateMMDDYYY,
      base64ToBlob,
      applicationStatus,
      store,
      docAvailable,
      showRestartLink,
      showResendInviteLink,
      showReturnLink,
      gettingData,
      inviteResent,
      secondaryApplicantInfo,
      isInvitedApplicant,
      isSecondaryApplicant,
      inviteAccepted,
      resendInviteError,
      secondaryApplicantIsRegistered,
      invite,
      cancelApplication,
      openCancellationModal,
      tollFreeNumber,
      emailAddress,
      soloApp,
      restartLink
    };
  },
  computed: {
    ...mapState(["lenderData", "configuration", "appData"]),
    ...mapGetters(["applicationStips"]),
    noticeReady(): boolean {
      return this.docAvailable ? this.docAvailable : false;
    },
    invitedApplicantInfo() {
      if ((this.appData?.applicants || []).length > 1) {
        return this.appData.applicants.find(
          (a: any) => a.applicantTypeId !== this.applicant?.applicantTypeId
        );
      } else {
        return {} as Applicant;
      }
    },
  },
  watch: {
    async "application.applicationStatusName"(value) {
      // Watches for application status to be = denied
      if (value.trim().toLowerCase() === 'declined') {
        await this.checkForLetter();
      }
    },
  },
  async mounted() {
    this.gettingData = true;

    if (this.application?.applicationStatusName.trim().toLowerCase() === 'not submitted') {
      await this.getApplicationStatusInfo();
    }

    if (this.application?.applicationStatusName.trim().toLowerCase() === 'declined') {
      await this.checkForLetter();
    }

    this.gettingData = false;
  },
  methods: {
    async getApplicationStatusInfo() {
      this.gettingData = true;
      // Solo app
      if (this.application.metadata.withCoApplicants === false) {
        this.showReturnLink = this.appData?.currentApplicant?.submittedAt === null || this.appData?.currentApplicant?.submittedAt === undefined;
      }
      // Joint app
      if (this.application.metadata.withCoApplicants === true) {

        let res =
          (await $api.applications.getApplicationInvites(
            this.application.id
          )) || [];

        if (res?.error || res?.length <= 0) {
          console.log("no invites found");
          /************** NORMAL FLOW CHECK isPresent = true **************/
          this.showResendInviteLink = false;

          // primary applicant is logged in and left off on isPresent step show return link
          if (this.appData?.applicants?.length <= 1) {
            this.isSecondaryApplicant = false;
            this.showReturnLink = true;
          }
          // Secondary applicant exists
          if (this.appData?.applicants?.length > 1) {
            this.secondaryApplicantInfo = this.appData.applicants.pop();
            this.isSecondaryApplicant =
              this.applicant?.applicantTypeId ===
              this.secondaryApplicantInfo?.applicantTypeId;
            console.log("secondary applicant", this.secondaryApplicantInfo);
            console.log("isSecondaryApplicant", this.isSecondaryApplicant);

            // Secondary applicant is logged in and has not submitted their portion
            if (
              this.isSecondaryApplicant === true &&
              this.applicant?.submittedAt === null
            ) {
              this.showReturnLink = true;
            }

            // Primary applicant is logged in
            if (this.isSecondaryApplicant === false) {
              // scenario: Primary applicant has NOT submitted their portion yet.
              if (this.applicant?.submittedAt === null) {
                this.showReturnLink = true;
                this.showRestartLink = false;
              }
              // scenario: secondary applicant is registered
              if (this.secondaryApplicantInfo?.userId !== null) {
                this.showRestartLink = false;
                this.showReturnLink = false;
                this.secondaryApplicantIsRegistered = true;
              }
              // scenario: secondary applicant abandoned application before registering
              if (
                this.applicant?.submittedAt !== null &&
                this.secondaryApplicantInfo?.userId === null
              ) {
                this.showRestartLink = true;
                this.showReturnLink = false;
                this.secondaryApplicantIsRegistered = false;
              }
            }
          }
        } else {
          /************** INVITE FLOW CHECK isPresent = false **************/
          let invites = res.invites;
          if ((invites || []).length > 0) {
            console.log("invites", invites);
            let invitedApplicant = invites[0];
            this.isInvitedApplicant = invitedApplicant.applicantTypeId === this.applicant?.applicantTypeId;
            this.inviteAccepted = invitedApplicant?.accepted;
            this.invite = invites[0];
            this.store.dispatch('setInvites', invites);

            console.log("invitedApplicant", invitedApplicant);
            console.log(
              "isInvitedApplicant",
              invitedApplicant.applicantTypeId ===
              this.applicant?.applicantTypeId
            );
            console.log("isInviteAccepted", this.inviteAccepted);
            console.log("this.invitedApplicantInfo", this.invitedApplicantInfo);

            // Check if invite has been resent
            if (invitedApplicant.resendAtUtc !== null) {
              this.inviteResent = true;
            } else {
              this.inviteResent = false;
            }
            // Secondary applicant is logged in and has not submitted their portion
            if (this.isInvitedApplicant === true && (this.appData?.currentApplicant?.submittedAt === null || this.appData?.currentApplicant?.submittedAt === undefined)) {
              this.showReturnLink = true;
            }

            // Primary applicant is logged in
            if (this.isInvitedApplicant === false) {
              this.showResendInviteLink = true;
              this.showReturnLink = undefined;

              // 1. Invite not accepted yet
              if (invitedApplicant?.accepted === false) {
                this.showResendInviteLink = true;
              }

              // 2. Invite accepted, but no username created
              if (
                invitedApplicant?.accepted === true &&
                (this.invitedApplicantInfo?.userId === null || this.invitedApplicantInfo?.userId === undefined)
              ) {
                this.showResendInviteLink = false;
                this.showRestartLink = true;
              }

              // 3. Invite accepted, secondary applicant IS registered
              if (
                invitedApplicant?.accepted === true &&
                this.invitedApplicantInfo?.userId !== null
              ) {
                this.showResendInviteLink = false;
                this.showRestartLink = false;
                this.secondaryApplicantIsRegistered = true;
              }
            }
          }
        }
      }
      console.log("current state");
      console.log("this.showResendInviteLink", this.showResendInviteLink);
      console.log("this.showRestartLink", this.showRestartLink);
      console.log("this.showReturnLink", this.showReturnLink);

      this.gettingData = false;
    },
  },
});
