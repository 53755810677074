import { Module } from "vuex";
import { $api } from "@/services/api";
import { Address, AddOrUpdateAddress, Applicant } from "@/models/application";
import { UpdateStipulationRequest, AddressInputRequest } from "@/models/api";
import store from ".";
import useHtmlUtilities from "@/use/htmlUtilities";
import { Stipulation } from "@/models/opal";

const { decodeHTMLEncodedStr } = useHtmlUtilities();

const formsModule: Module<any, any> = {
  namespaced: true,
  state: {
    address: {} as AddOrUpdateAddress,
    addressForm: {
      id: 0,
      applicantId: null,
      address1: null,
      address2: null,
      addressTypeId: null,
      addressTypeName: null,
      addressUnitTypeId: null,
      addressUnitTypeName: null,
      city: null,
      countryId: null,
      countryName: null,
      postalCode: null,
      stateId: null,
      stateName: null,
      stipulationId: null,
    },
    currentAddressForm: {
      id: 0,
      applicantId: null,
      address1: null,
      address2: null,
      addressTypeId: null,
      addressTypeName: null,
      addressUnitTypeId: null,
      addressUnitTypeName: null,
      city: null,
      countryId: null,
      countryName: null,
      postalCode: null,
      stateId: null,
      stateName: null,
      stipulationId: null,
    },
    membershipForm: {},
    previousAddresses: [] as Address[],
    borrowerPreviousAddresses: [] as Address[],
    coborrowerPreviousAddresses: [] as Address[],
    stipulation: {} as Stipulation,
    stipulationUpdateRequest: {
      stipulationStatusId: null,
      stipulationStatusReasonId: null,
      stipulationTextOverride: null,
      revertToDefaultText: null,
      updatedByUserId: null,
    },
  },
  getters: {
    addressLine2: (state: any): string => {
      const unitType = state.lookups.addressUnits.find(
        (a: any) => a.id === state.address.addressUnitTypeId,
      );
      if (unitType) {
        return `${unitType.abbreviation} ${state.newAddress.address2}`;
      }
      return state.newAddress.address2;
    },
    AddressInputRequest: (state: any): AddressInputRequest => {
      const address: AddressInputRequest = {
        address1: state.currentAddressForm.address1,
        address2: state.currentAddressForm.address2,
        addressUnitTypeId: state.currentAddressForm.addressUnitTypeId,
        city: state.currentAddressForm.city,
        countryId: state.currentAddressForm.countryId,
        postalCode: state.currentAddressForm.postalCode,
        stateId: state.currentAddressForm.stateId,
      };
      return address;
    },
    AddressRequest: (state: any): AddOrUpdateAddress => {
      const address: AddOrUpdateAddress = {
        addressId: state.addressForm.id,
        applicantId: state.addressForm.applicantId,
        address1: state.addressForm.address1,
        address2: state.addressForm.address2,
        addressTypeId: state.addressForm.addressTypeId,
        addressTypeName: state.addressForm.addressTypeName,
        addressUnitTypeId: state.addressForm.addressUnitTypeId,
        addressUnitTypeName: state.addressForm.addressUnitTypeName,
        city: state.addressForm.city,
        countryId: state.addressForm.countryId,
        countryName: state.addressForm.countryName,
        postalCode: state.addressForm.postalCode,
        stateId: state.addressForm.stateId,
        stateName: state.addressForm.stateName,
      };
      return address;
    },
    borrowerPreviousAddresses: (state: any): any => {
      return state.borrowerPreviousAddresses;
    },
    coborrowerPreviousAddresses: (state: any): any => {
      return state.coborrowerPreviousAddresses;
    },
  },
  mutations: {
    addPreviousAddress(state: any, address: Address) {
      state.previousAddresses.push(address);
    },
    setCurrentAddressForm(state: any, currentAddressForm: any) {
      const unitType = store.state.lookupsModule.lookups.addressUnits.find(
        (a: any) => a.id === currentAddressForm.addressUnitTypeId,
      );
      state.currentAddressForm.applicantId = currentAddressForm.applicantId;
      state.currentAddressForm.address2 = unitType
        ? `${unitType.abbreviation} ${currentAddressForm.address2}`
        : null;
      state.currentAddressForm = currentAddressForm;
    },
    setAddressForm(state: any, addressForm: any) {
      const unitType = store.state.lookupsModule.lookups.addressUnits.find(
        (a: any) => a.id === addressForm.addressUnitTypeId,
      );
      const addressType = store.state.lookupsModule.lookups.addressTypes.find(
        (t: any) => t.name === "Previous Address",
      );
      state.addressForm.applicantId = addressForm.applicantId;
      state.addressForm.addressTypeId = addressType ? addressType.id : null;
      state.addressForm.address2 = unitType
        ? `${unitType.abbreviation} ${addressForm.address2}`
        : null;
      state.addressForm = addressForm;
    },
    resetAddressForm(state: any) {
      state.addressForm = {
        id: 0,
        applicantId: 0,
        address1: null,
        address2: null,
        addressTypeId: null,
        addressTypeName: null,
        addressUnitTypeId: null,
        addressUnitTypeName: null,
        city: null,
        countryId: null,
        countryName: null,
        postalCode: null,
        stateId: null,
        stateName: null,
      };
    },
    resetCurrentAddressForm(state: any) {
      state.currentAddressForm = {
        id: 0,
        applicantId: 0,
        address1: null,
        address2: null,
        addressTypeId: null,
        addressTypeName: null,
        addressUnitTypeId: null,
        addressUnitTypeName: null,
        city: null,
        countryId: null,
        countryName: null,
        postalCode: null,
        stateId: null,
        stateName: null,
      };
    },
    setBorrowerPreviousAddresses(state: any, addresses: Address[]) {
      state.borrowerPreviousAddresses = addresses;
    },
    setCoborrowerPreviousAddresses(state: any, addresses: Address[]) {
      state.coborrowerPreviousAddresses = addresses;
    },
    addCoborrowerPreviousAddress(state: any, address: Address) {
      state.coborrowerPreviousAddresses.push(address);
    },
    addBorrowerAddress(state: any, address: Address) {
      state.borrowerPreviousAddresses.push(address);
    },
  },
  actions: {
    setAddress({ commit }, payload) {
      commit("setAddress", payload);
    },
    setAddressForm({ commit }, payload) {
      commit("setAddressForm", payload);
    },
    resetAddressForm({ commit }) {
      commit("resetAddressForm");
    },
    setCurrentAddressForm({ commit }, payload) {
      commit("setCurrentAddressForm", payload);
    },
    resetCurrentAddressForm({ commit }) {
      commit("resetCurrentAddressForm");
    },
    addCoborrowerPreviousAddress({ commit }, payload) {
      commit("addCoborrowerPreviousAddress", payload);
    },
    addBorrowerPreviousAddress({ commit }, payload) {
      commit("addBorrowerPreviousAddress", payload);
    },
    setBorrowerPreviousAddresses({ commit }, payload) {
      commit("setBorrowerPreviousAddresses", payload);
    },
    setCoborrowerPreviousAddresses({ commit }, payload) {
      commit("setCoborrowerPreviousAddresses", payload);
    },
    getPreviousAddresses({ commit }, applicantId: number) {
      const applicant = store.state.appData.applicants.find((a: Applicant) => a.id === applicantId);
      const applicantAddresses = store.state.appData.applicants
        .find((a: Applicant) => a.id === applicantId)
        ?.addresses.filter((a: any) => a.addressTypeName === "Previous Address");
      applicantAddresses.forEach((a: Address) => {
        a.address1 = decodeHTMLEncodedStr(a.address1);
        a.address2 = decodeHTMLEncodedStr(a.address2);
        a.city = decodeHTMLEncodedStr(a.city);
      });

      applicant && applicant.applicantTypeName === "PrimaryApplicant"
        ? commit("setBorrowerPreviousAddresses", applicantAddresses)
        : commit("setCoborrowerPreviousAddresses", applicantAddresses);
      return applicantAddresses;
    },
    async addAddress({ commit, dispatch }, payload) {
      const request = store.getters["forms/AddressRequest"];

      const response = await $api.applicant.addAddress(payload.applicantId, request);
      const stipId = store.state.forms.addressForm.stipulationId;
      if (response.id && response.id > 0) {
        const applicant = store.state.appData.applicants.find(
          (a: Applicant) => a.id === response.applicantId,
        );

        applicant.addresses.push(response);

        // await store.dispatch('checklistModule/updateApplicationStipulationStatus', { status: "Pending", stipulationId: stipId });
        await store.dispatch("updateApplicantAddresses", {
          applicantId: applicant.id,
          addresses: store.state.previousAddresses,
        });
        commit("resetAddressForm");
        dispatch("modal/closeAddressModal");
      } else {
        return false;
      }
    },
  },
};

export default formsModule;
