
import { ImageConstants } from "@/config/constants";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import useHtmlUtilities from "@/use/htmlUtilities";
import useFormatter from "@/use/formatter";

export default defineComponent({
  props: [],
  name: "Hero",
  setup(){
    const { decodeHTMLEncodedStr } = useHtmlUtilities();
    const { formatName } = useFormatter();
    return { decodeHTMLEncodedStr, formatName }
  },
  computed: {
    ...mapGetters(["getCdnImagesUrl"]),
    ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcUser"]),
    backgroundImage() {
      return `${this.getCdnImagesUrl()}/${ImageConstants.BACKGROUND_GRAD}`;
    },
  },
});
