export class ImageConstants {
  public static get BACKGROUND_GRAD(): string {
    return "background-grad.png";
  }
  public static get APP_ARCHIVE(): string {
    return "app-archive-rev.png";
  }
  public static get APPLY_STUDENT_LOAN(): string {
    return "apply-student-loan-rev.png";
  }
  public static get FOOTER_LOGO(): string {
    return "footer-logo-cusc.png";
  }
  public static get MODAL_CLOSE(): string {
    return "modal-close.png";
  }
}
