import { Module } from "vuex";
import store from ".";
import { Applicant } from "@/models/application";
import { $api } from "@/services/api";
import useChecklistHelpers from "@/use/checklistHelpers";
import { Stipulation } from "@/models/opal";
const { title, processingCategoryTitle, groupItems } = useChecklistHelpers();


function isComplete(items: any) {
  const completed = items.every((subitem: any) => subitem.stipulationStatusName === "Completed" || subitem.stipulationStatusName === "CU-Override");
  return completed;
}

function getApplicant(applicantId: number) {
  return store.state.appData.applicants.find((a: Applicant) => a.id === applicantId) || false;
}

function getEsignViewModel(stip: any) {
  const applicant = getApplicant(stip.applicantId);
  const path = applicant ? applicant.applicantTypeName === 'Primary Applicant' ? "Borrower" : "Coborrower" : "";

  const viewModel = {
    title: `Final Documents`,
    name: `${path}FinalDocuments`,
    type: "button",
    buttonText: "Click Here to Sign",
    collapsible: store.state.stipsData.length > 1,
    ...stip,
  }
  return viewModel;
}

const checklistModule: Module<(any), any> = {
  namespaced: true,
  state: {
    checklistItems: [],
    sortedApplicationStips: {},
    sortedItems: [],
    esignAvailable: false,
    esignCompleted: false,
    showIncomeVerification: undefined as undefined | boolean,
    showCompletedItems: false,
    incomeSources: []
  },
  getters: {
    incomeStipVM() {
      const category = store.state.lookupsModule.lookups.processingCategories.find((c:any) => {
        c.name === 'Income'
      });
      const stipStatus = store.state.lookupsModule.lookups.stipStatuses.find((s: any) => {
        s.name === 'Open'
      });
      
      const path = store.state.appData.application?.metadata?.withCoApplicants === true ? 'Coborrower' : 'Borrower';

      return {
        title: "Income Verification",
        customUI: true,
        processingCategoryId: category.id,
        processingCategoryName: category.name,
        items: [
          {
            name: `${path}IncomeVerification`,
            title: `${path} Income Verification`,
            stipulationStatusId: stipStatus.id,
            stipulationStatusName: stipStatus.name,
            subItems: [
              { title: "Paycheck from an Employer", name: "paystub", type: "checkbox" },
              { title: "Income from a Business You Own", name: "scheduleC", type: "checkbox" },
              { title: "Social Security Benefits", name: "SSA1099", type: "checkbox" },
              { title: "Distributions from a Pension or Retirement Account", name: "retirement", type: "checkbox" },
              { title: "Income from a Rental or Investment Property", name: "scheduleE", type: "checkbox" },
              { title: "Other Income Source", name: "otherIncome", type: "checkbox" },
            ]
          }
        ]
      }
    },
    ssnVerificationStipVM(){
      const ssnMismatchStip = store.state.stipsData.find((ci: any) => ci.stipulationTypeName === "SSNMistmatchProofSSN") || false;
      // ssnMismatchStip.docTypeId = store.state.lookupsModule.lookups.documentTypes.find((dt: any)=> dt.name === 'Social Security Card');
      if (ssnMismatchStip) {
        return {
          title: "SSN Verification",
          customUI: false,
          collapsible: store.state.stipsData.length > 1,
          ...ssnMismatchStip,
        }
      } else {
        return;
      }

    },
    addressMismatchStipVM() {
      const addressMismatchStip = store.state.stipsData.find((ci: any) => ci.stipulationTypeName === "ADDMismatch") || false;

      const applicant = getApplicant(addressMismatchStip.applicantId);
      const path = applicant ? applicant.applicantTypeName === 'Primary Applicant' ? "Borrower" : "Coborrower" : "" 
      if (addressMismatchStip) {
        return {
          section: `${path} Address Verification`,
          title: "Address Verification",
          name: `${path}AddressVerification`,
          customUI: true,
          collapsible: store.state.stipsData.length > 1,
          ...addressMismatchStip
        }
      } else {
        return;
      }
    },
    checklistItems() {
      const keys = Object.keys(store.state.checklistModule.sortedApplicationStips);
      const result = keys.map((k: string) => {
        if (k === 'Final Documentation') {
          Object.values(store.state.checklistModule.sortedApplicationStips[k]).forEach((s: any) => {
            const viewModel = getEsignViewModel(s);
            Object.assign(s,viewModel);
          });

          return {
            title: `Final Documents`,
            items: Object.values(store.state.checklistModule.sortedApplicationStips[k])
          }
        } else {
          return;
        }
      });
      for (const res of Object.values(result)) {
        if (res) {
          store.dispatch('addChecklistItem', res)
        }
      }
    },
    sortedItems(state: any) {
      return state.sortedItems;
    },
    esignAvailable: (state: any) => {
      return state.esignAvailable;
    },
    esignCompleted: (state: any) => {
      return state.esignCompleted;
    },
    incomeSources: (state: any) => {
      return state.incomeSources;
    },
  },
  mutations: {
    setSortedStips(state: any, sortedStips: any[]) {
      state.sortedStips = sortedStips;
    },
    setSortedItems(state: any, sorted: any) {
      state.sortedItems = sorted;
    },
    setSortedApplicationStips(state: any, stips: any) {
      state.sortedApplicationStips = stips;
    }, 
    esignAvailable(state: any, flag: boolean) {
      state.esignAvailable = flag;
    },
    esignComplete(state: any, flag: boolean) {
      state.esignCompleted = flag;
    },
    addChecklistItem(state: any, item: any) {
      state.checklistItems.push(item);
    },
    setChecklistItems(state: any, items: any) {
      state.checklistItems = items;
    },
    setShowCompletedItems(state: any, flag: boolean) {
      state.showCompletedItems = flag;
    },
    updateSortedChecklistItem(state: any, stip: any) {
      state.checklistModule.sortedApplicationStips[stip.title] = stip;
    },
    setIncomeSources(state: any, sources: any) {
      state.checklistModule.incomeSources = sources;
    },
    setShowIncomeVerification(state: any, flag: boolean) {
      state.showIncomeVerification = flag;
    },
  },
  actions: {
    sortStips({commit}, payload) {
      commit('setSortedStips', payload);
    },
    setSortedItems({commit}, payload) {
      commit('setSortedItems', payload);
    },
    setSortedApplicationStips({commit}, payload) {
      commit('setSortedApplicationStips', payload);
    },
    setEsignAvailableFlag({commit}, payload) {
      commit('esignAvailable', payload);
    },
    setEsignCompleteFlag({commit}, payload) {
      commit('esignComplete', payload);
    },
    setShowCompletedItems({commit}, payload) {
      commit('setShowCompletedItems', payload);
    },
    updateSortedChecklistItem({commit}, payload) {
      commit('updateSortedChecklistItem', payload);
    },
    setIncomeSources({commit}, payload) {
      commit('setIncomeSources', payload);
    },
    setShowIncomeVerification({commit}, payload) {
      commit('setShowIncomeVerification', payload);
    },
    async updateApplicationStipulationStatus({commit}, payload){
      const stipulation = store.state.stipsData.find((s: Stipulation) => s.id === payload);
      const stipulationStatusId = store.state.lookupsModule.lookups.stipStatuses.find((s: any) => s.name === `${payload.status}`);
      const userId = store.state.oidcStore.oidcUser.sub;
      const updateStipRequest = {
        stipulationStatusId: stipulationStatusId,
        updatedByUserId: userId
      };

      const response = await $api.applications.updateApplicationStipulation(stipulation.applicationId, stipulation.id, updateStipRequest);
      console.log('updateApplicationStipulationStatus()', response);      
    },
    async getSortedChecklistItems({commit}) {
      const applicationStips = await $api.applications.getApplicationStips(store.state.appData.application?.id);
      if ((applicationStips?.results || []).length > 0) {
        store.dispatch("setStipsData", applicationStips?.results);
        const esignStip = applicationStips?.results.filter((s: any) => (s.stipulationTypeName === 'ExecuteLegalDocPackage' && s.applicantId === store.state.appData.currentApplicant.id));
        const stips = applicationStips?.results.filter((s: any) => (s.stipulationTypeName !== 'ExecuteLegalDocPackage'));
        stips.push(esignStip[0]);
        stips.forEach((result: any) => {
          result.processingCategoryTitle = processingCategoryTitle(result);
        });
        const sortedApplicationStips = groupItems(stips, "processingCategoryTitle");
        const stipGroups = Object.keys(sortedApplicationStips);
        const sortedItems = stipGroups.map((k: string) => {
          return {
            title: k,
            formattedTitle: title(k),
            complete: isComplete(Object.values(sortedApplicationStips[k])),
            items: Object.values(sortedApplicationStips[k])
          }
        });
        const completedItems = sortedItems.filter((item: any) => item.complete);
        commit('setSortedItems', sortedItems);
        commit('setShowCompletedItems', completedItems.length > 0);
        commit('setSortedApplicationStips', sortedApplicationStips);
        store.dispatch('checklistModule/getEsignStatus');
      }
    },
    getEsignStatus({commit}) {
      const esignAvailableFlag = store.state.checklistModule.sortedApplicationStips['Final Documentation'] && store.state.checklistModule.sortedApplicationStips['Final Documentation'].length > 0
      commit('esignAvailable', esignAvailableFlag);
      if (esignAvailableFlag) {
        const esignStips = store.state.checklistModule.sortedApplicationStips['Final Documentation'].filter((s: any) => (s.stipulationTypeName === 'ExecuteLegalDocPackage' && s.applicantId === store.state.appData.currentApplicant.id));
        const completed = esignStips[0].stipulationStatusName === "Completed"; 
        // .every((esignStip: any) => esignStip.stipulationStatusName === 'Completed')
        commit('esignComplete', completed);
      }
    },
  },
  }
  export default checklistModule;