import { Module } from "vuex";
import { $api } from "@/services/api";
import { Option } from "@/models/form";
import { Address } from "@/models/application";
import { FeedbackRequestOption } from "@/models/feedback";

const lookupsModule: Module<any, any> = {
  namespaced: true,
  state: {
    lookups: undefined as any,
    statesOptions: [] as Option[],
    addressUnitsOptions: [] as Option[],
    countryOptions: [] as Option[],
    addressTypes: [] as any[],
    feedbackFormOptions: [] as FeedbackRequestOption[],
  },
  getters: {
    statesOptions: (state: any): Array<Option> => {
      return state.statesOptions;
    },
    addressUnitsOptions: (state: any): Array<Option> => {
      return state.addressUnitsOptions;
    },
    countryOptions: (state: any): Array<Option> => {
      return state.countryOptions;
    },
    addressTypes: (state: any): Array<any> => {
      return state.addressTypes;
    },
    feedbackFormOptions: (state: any): Array<any> => {
      return state.feedbackFormOptions;
    },
  },
  mutations: {
    setLookups(state: any, lookups: any[]) {
      state.lookups = lookups;
    },
    setStateOptions(state: any, states: any[]) {
      state.statesOptions = states;
    },
    setAddressUnitsOptions(state: any, addressUnits: any[]) {
      state.addressUnitsOptions = addressUnits;
    },
    setCountryOptions(state: any, countries: any[]) {
      state.countryOptions = countries;
    },
    setAddressTypes(state: any, addressTypes: any[]) {
      state.addressTypes = addressTypes;
    },
    setAddress(state: any, address: Address) {
      state.address = address;
    },
    setFeedbackFormOptions(state: any, options: Address) {
      state.feedbackFormOptions = options;
    },
  },
  actions: {
    async getLookups({ commit }) {
      try {
        const lookups = await $api.lookups.getLookups();

        if (lookups) {
          commit("setLookups", lookups);
        }
        if (lookups.addressTypes) {
          commit("setAddressTypes", lookups.addressTypes);
        }
        if (lookups.states) {
          const statesOptions = lookups.states.map((c: any) => {
            return {
              label: c.name,
              value: c.id,
            };
          });

          commit("setStateOptions", statesOptions);
        }

        if (lookups.addressUnits) {
          const addressUnitsOptions = lookups.addressUnits.map((c: any) => {
            return {
              label: c.name,
              value: c.id,
            };
          });
          commit("setAddressUnitsOptions", addressUnitsOptions);
        }
        if (lookups.countries) {
          const countryOptions = lookups.countries.map((c: any) => {
            return {
              label: c.name,
              value: c.id,
            };
          });
          commit("setCountryOptions", countryOptions);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getFeedbackFormOptions({ state, commit }) {
      try {
        if (state.feedbackFormOptions?.length <= 0) {
          const options = await $api.feedback.getFeedbackFormOptions(3);
          commit("setFeedbackFormOptions", options);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
export default lookupsModule;
