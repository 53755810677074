
import { defineComponent, reactive } from "vue";
import useApplications from "@/use/application";
import useFormatter from "@/use/formatter";
import Page from "@/components/shared/layout/Page.vue";
import ApplicationInfo from "@/components/ApplicationInfo.vue";
import ApplicationStatus from "@/components/ApplicationStatus.vue";
import ApplicationChecklist from "@/components/ApplicationChecklist.vue";
import ApplicationFileUpload from "@/components/ApplicationFileUpload.vue";
import Loader from "@/components/shared/Loader.vue";
import { mapActions, mapGetters, mapState, useStore } from "vuex";
import * as jsonPatch from "fast-json-patch";
import { $api } from "@/services/api";
import { Application, Applicant, LoanConfig } from "@/models/application";
import { Stipulation } from "@/models/opal";

export default defineComponent({
  setup() {
    const store = useStore();
    const {
      getUserApplications,
      getUserApplication,
      getUserApplicationApplicant,
    } = useApplications();
    const { formattedDateFormat} = useFormatter();
    const applicationStips = reactive([] as Stipulation[]);
    return {
      applicationStips,
      store,
      getUserApplications,
      getUserApplication,
      getUserApplicationApplicant,
      formattedDateFormat,
    };
  },
  components: {
    Page,
    ApplicationInfo,
    ApplicationStatus,
    ApplicationChecklist,
    ApplicationFileUpload,
    Loader,
  },
  data: () => ({
    activeApplication: undefined || ({} as Application),
    applicationStatus: undefined || {},
    applicants: undefined || ([] as Applicant[]),
    applicant: undefined || ({} as Applicant),
    applicationStatusName: "" as undefined | string,
    applicantDocuments: undefined || ([] as any[]),
    applicationDocuments: undefined || ([] as any[]),
    documents: undefined || ([] as any[]),
    loading: true,
    appObserver: {} as jsonPatch.Observer<Application>,
  }),
  computed: {
    ...mapState(["applications", "configuration", "appData"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("fundsModule", ["Loan"]),
    isLoanBoarded(): boolean {
      return this.appData?.application?.loanId > 0 && this.Loan?.loanBoarded ? true : false;
    },
    showApplicationDownloadTile(): boolean {
      switch ((this.applicationStatusName || "").trim().toLowerCase()) {
        case "submitted & processing":
        case "complete":
          return true;
        default:
          return false;
      }
    },
    showTile(): boolean {
      switch ((this.applicationStatusName || "").trim().toLowerCase()) {
        case "not submitted":
        case "declined":
          return false;
        default:
          return true;
      }
    },
  },
  methods: {
    ...mapActions(["setApplications", "setAppData"]),
    async stepComplete(data: any) {
      this.loading = true;
      await this.updateAppData();
    },
    async updateAppData(person: any = undefined) {
      // 1. Gather the Application Patch object
      console.log(
        "Gather the Application Patch object",
        this.appData.application
      );
      const applicationPatch = jsonPatch.generate(this.appObserver);

      const updatedAppData = await $api.applications.updateApplication(
        this.appData.currentApplicant.userId,
        this.appData.id,
        applicationPatch
      );

      console.log(
        "Application.vue updateAppData() UPDATED APP",
        updatedAppData
      );

      if (!updatedAppData.error || !updatedAppData.id) {
        await this.store.dispatch("setAppDataApplicationAndApplicants", updatedAppData);
        this.activeApplication = this.appData.application;
      } else {
        alert("Something has gone wrong");
      }
    },
    async hydrateStore() {
      if (this.applications.length <= 0) {
        const applications = await this.getUserApplications(this.oidcUser.sub);
        await this.setApplications(applications);
      }

      const response = await $api.applications.getUserApplicationByRefId(
        this.oidcUser.sub,
        this.$route.params?.referenceId as string,
        0
      );

      if (response.currentApplicant && response.appData) {
        this.applicant = response.currentApplicant[0];
        this.activeApplication = response.appData.application;
        this.applicants = response.appData.applicants;
      }

      if (this.activeApplication) {
        this.applicationStatusName = this.activeApplication.applicationStatusName;
        let applicationStipsResponse = await $api.applications.getApplicationStips(this.activeApplication?.id);

        const applicationTypeId = this.activeApplication?.metadata?.withCoApplicants === true ? 2 : 1;
        const incomeLimit = applicationTypeId === 1 ? 'BorrowerMinIncomeCondApproved' : 'CoBorrowerMinIncomeCondApproved';
        const limitTypeId =  applicationTypeId === 1 ? 9 : 28;
        const dateToCheck = this.activeApplication?.submittedAtUtc ? this.formattedDateFormat(this.activeApplication?.submittedAtUtc, "YYYY-MM-DD") : this.activeApplication?.submittedAtUtc;
        if (dateToCheck !== null) {
          const incomeLimitExists = await $api.programpricing.incomeLimitCheck(this.activeApplication?.lenderId as number, this.activeApplication?.programTypeId, this.activeApplication?.productTypeId, limitTypeId, dateToCheck as Date);
          this.store.dispatch("checklistModule/setShowIncomeVerification", incomeLimitExists);
        }
        if ((applicationStipsResponse?.results || []).length > 0) {
          this.applicationStips = applicationStipsResponse?.results;
        }
      }

      console.log("Active App", this.activeApplication);
      await this.setAppData({
        applicants: this.applicants,
        application: this.activeApplication,
        currentApplicant: this.applicant,
        applicationStips: this.applicationStips,
        applicantDocuments: [],
        applicationDocuments: [],
      });

      await this.store.dispatch("lookupsModule/getLookups");

      console.log("appData", this.appData);
    },
    refreshObservers() {
      if (this.appData) {
        if (this.appData.application) {
          if (this.appData.application.schoolConfiguration === null) {
            this.appData.application.schoolConfiguration = undefined;
          }
          if (
            this.appData.application.loanConfiguration === null ||
            this.appData.application.loanConfiguration === ([] as any[])
          ) {
            this.appData.application.loanConfiguration = {} as LoanConfig;
          }
          this.appObserver = jsonPatch.observe(this.appData.application);
        }
      }
    },
  },
  watch: {
    $route() {
      console.log("route changed...");
    },
    appData() {
      console.log("Application Update");
      this.refreshObservers();
    },
  },
  async mounted() {
    // rehydrate vuex stores
    await this.hydrateStore();
    this.loading = false;
  },
});
